<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
          <div class="tab">
              <el-tabs v-model="activeName" @tab-click="handleClick"  style="margin-left:25px">
                <el-tab-pane label="待设计" name="one"></el-tab-pane>
                <el-tab-pane label="设计记录" name="two"></el-tab-pane>
                </el-tabs>
         
        <div class="SearchBar" v-if="activeName=='two'">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="设计时间" class="lab">
               <el-date-picker
                class="SearchDate"
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="用户类型" class="lab">
              <el-select v-model="search.userTypeCode" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            </el-form-item>
        
            <el-form-item label="工单号" class="lab">
              <el-input v-model="search.orderCode" clearable></el-input>
            </el-form-item>
           
           <span style="float:right;width:150px">
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
           </span>
          </el-form>
        </div>
        <div class="table">
          <div :class="activeName=='one'?'table-height1':'table-height'">
          <!--数据表格-->
          <el-table :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData1" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='one'"
             
            :stripe="true"
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search1.current)-1)*parseInt(search1.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="orderCode"
              label="工单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="userTypeCodeName"
              label="用户类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="equipmentCodeName"
              label="用气设备"
              align="center"
              show-overflow-tooltip
            >
           
            </el-table-column>
            <el-table-column
              prop="address"
              label="执行位置"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="imageUrlList"
              label="执行简图"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="showImg(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="applyId"
              label="报装人信息"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="desc(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="surveyorName"
              label="测量员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="checkTime"
              label="审核时间"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="orderStatusName"
              label="状态"
              align="center"
              show-overflow-tooltip
             
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.orderStatus==1" type="primary" style="background-color:#1C64F6 ;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==2" type="info" style="background-color:#EFAC0B;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==3" type="success" style="background-color:#CD9340;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==4" type="warning" style="background-color:#28C8F0;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==5" type="primary" style="background-color:#FF6C32;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==6" type="info" style="background-color:#449BFF;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==7" type="success" style="background-color:#32C655;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==8" type="warning" style="background-color:#5773DC;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==9" type="primary" style="background-color:#FF9C32;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==10" type="info" style="background-color:#83CF5D;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==11" type="success" style="background-color:#F55151;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==12" type="success" style="background-color:#BFBFBF;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
            </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="100">
              <template slot-scope="{ row }">
                   <el-button 
                    type="text"
                    size="small"
                    @click="addShow(row,1)" 
                    >上传设计</el-button>
                      
                    
              </template>
            </el-table-column>
          </el-table>
          <el-table :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData2" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='two'"
             
            :stripe="true"
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="orderCode"
              label="工单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="userTypeCodeName"
              label="用户类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="equipmentCodeName"
              label="用气设备"
              align="center"
              show-overflow-tooltip
            >
           
            </el-table-column>
            <el-table-column
              prop="address"
              label="执行位置"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="imageUrlList"
              label="执行简图"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="showImg(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="applyId"
              label="报装人信息"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="desc(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="surveyorName"
              label="测量员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="designTime"
              label="设计时间"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="designUserName"
              label="设计人"
              align="center"
              show-overflow-tooltip
             
            >
            
            </el-table-column>
             <el-table-column
              prop="orderStatusName"
              label="状态"
              align="center"
              show-overflow-tooltip
             
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.orderStatus==1" type="primary" style="background-color:#1C64F6 ;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==2" type="info" style="background-color:#EFAC0B;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==3" type="success" style="background-color:#CD9340;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==4" type="warning" style="background-color:#28C8F0;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==5" type="primary" style="background-color:#FF6C32;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==6" type="info" style="background-color:#449BFF;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==7" type="success" style="background-color:#32C655;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==8" type="warning" style="background-color:#5773DC;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==9" type="primary" style="background-color:#FF9C32;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==10" type="info" style="background-color:#83CF5D;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==11" type="success" style="background-color:#F55151;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
              <el-tag v-if="row.orderStatus==12" type="success" style="background-color:#BFBFBF;color:#fff" size="small" plain>{{row.orderStatusName}}</el-tag>
            </template>
            </el-table-column>
            
           <el-table-column prop="operation" label="操作" align="left" width="100">
              <template slot-scope="{ row }">
                   <el-button  
                    type="text"
                    size="small"
                    @click="look(row,1)" 
                    >查看设计</el-button>
                      
                    
              </template>
            </el-table-column>
          
          </el-table>
          </div>
          <div class="pageBox">
          <el-pagination
            :current-page="activeName=='two'?search.current:search1.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="activeName=='two'?search.size:search1.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
         </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
    <el-form ref="addform" :model="form" label-width="120px" label-position="top"  size="mini" >
        <div >
          
          <el-form-item label="设计图:">
              <span slot="label">
             设计图:<span style="color:red;margin-left:4px">*</span>
            </span>
            <div>
                <el-upload
                ref="upload"
                action="#"
                :auto-upload="true"
                :http-request="repairBeforUploadFun"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-upload="beforUploadImg"
                :on-change="beforHFhandleChangeImg"
                :file-list="repairBeforFilefileList"
                 accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                 :limit="20"
                 :on-exceed="msgLimit"
                >
                <div style="height:20px">
                <img src="../../assets/image/upload.png"/>
                </div>
                <div>
                <span>上传图片</span>
                </div>
            </el-upload>
            </div>
              
          </el-form-item>
          <el-form-item label="补充说明:" >
             <el-input type="textarea" v-model="form.remark"   :autosize="{ minRows: 2, maxRows: 4}" maxlength="100"   ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="activeName=='one'">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="saveadd">保存</el-button>
     </div>
    </el-dialog>
      <el-dialog
      :visible.sync="dialogVisibleDesc"
      width="40%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
    <el-form ref="addform" :model="form" label-width="120px" label-position="top"  size="mini" >
        <div >
          
          <el-form-item label="设计图:">
              <span slot="label">
             设计图:<span style="color:red;margin-left:4px">*</span>
            </span>
            <div>
                 <el-image 
                style="width: 100px; height: 100px"
                :src="form.designImageUrlList[0]" 
                :preview-src-list="form.designImageUrlList">
            </el-image>
            <span style="margin-left:10px">共{{form.designImageUrlList.length}}张</span>
            </div>
              
          </el-form-item>
          <el-form-item label="补充说明:" >
             <el-input type="textarea" v-model="form.remark"   :autosize="{ minRows: 2, maxRows: 4}" maxlength="100"  disabled ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="图片预览"
      :visible.sync="dialogVisibleImg"
      width="100%"
      :close-on-click-modal="false"
    >
    <div><img :src="dialogImageUrl" width="100%"/></div>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
    <apply-desc :id=id :type="type" ref="applyDesc"></apply-desc>
  </div>
</template>
<script>
import request from '@/common/js/request'
import {selectDictListByParentCode } from '@/apis/commonType'
import {bzWorkOrderCheck_pageDesign,bzWorkOrderCheck_pageDesignRecord,bzWorkOrderCheck_getDesignUser,bzWorkOrderCheck_uploadDesign} from '../../RequestPort/gongfu/gongfu'
import {uploadImg1} from "../../RequestPort/apply/apply.js"
import showImg from './showImg.vue'
import applyDesc from '../report/applyDesc.vue'
import getNowFormatDate from "./../../common/js/nowDate.js";
export default {
  name: 'reportApply',
  components: {showImg,applyDesc},
  mixins: [],
  data() {
    return {
      userList:[],
      search1: { current: 1, size: 100}, //搜索
      search: { current: 1, size: 100}, //搜索
      searchDatatime:[],
      total: 0,
      tableData1: [], //列表对象
      tableData2: [],
      id:'',
      type:'',
      activeName:'one',
      imgList:[],
      dialogVisible:false,
      form:{designImageUrlList:[]},
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList:[],
      dialogVisibleImg:false,
      dialogImageUrl:'',
      dialogVisibleDesc:false,
      Scope:''
    }
  },
  mounted() {
      this.Scope = localStorage.getItem('dataScope');
      this.getOption()
      this.getList();
  },
  methods: {
      getOption(){
          if(this.activeName=='two'){
            let code = 'YH_TYPE';
            selectDictListByParentCode({parentCode:code}).then(res=>{
            if(res.code==200){
                this.userList = []
                res.data.forEach(el=>{
                  if(el.code!='YH_TYPE5'){
                    this.userList.push(el);
                  }
                })
                
            }
            })
          }
      },
      handleClick(val){
          
          this.getList(1);
      },
      showImg(row){
          if(row.imageUrlList&&row.imageUrlList.length>0){
             this.$refs.imgList.setdialogVisible(true);
             this.imgList = row.imageUrlList;
          }else{
              this.$message.error('暂无执行简图')
          }
          
      },
      addShow(row){
        console.log(this.Scope);
          if(this.Scope==1){
            bzWorkOrderCheck_getDesignUser({orderCode:row.orderCode}).then(resdata=>{
              if(resdata.data){
                  this.$confirm('工单上次设计员为【'+resdata.data+'】，是否继续', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  closeOnClickModal:false,
                  type: 'warning'
                }).then(() => {
                  this.form.id = row.id;
                  this.form.designImageUrlList =[];
                  this.repairBeforFilefileList=[];
                  this.dialogVisible = true;
                })
              }else{
                this.form.id = row.id;
                this.form.designImageUrlList =[];
                this.repairBeforFilefileList=[];
                this.dialogVisible = true;
              }
              
            })
             
          }else{
            this.form.id = row.id;
            this.form.designImageUrlList =[];
            this.repairBeforFilefileList=[];
            this.dialogVisible = true;
          }
          this.form.orderCode = row.orderCode
      },
     repairBeforUploadFun(e){
      this.repairBeforFileFormData = new FormData();
      this.repairBeforFileFormData.append('file',e.file)
      this.repairBeforFileFormData.append('text','工程单号：'+this.form.orderCode+'##用户名：'+localStorage.getItem("userName")+'##当前时间：'+getNowFormatDate())
      uploadImg1(this.repairBeforFileFormData).then((res)=> {
         this.form.designImageUrlList.push(res.data.url)
        this.repairBeforFileFormData.delete('file')
      })
      },
      beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['jpg','jpeg','png','gif','bmp','JPG','JPEG','PBG','GIF','BMP'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 jpg、jpeg、png、gif、bmp");
        return false;
      }
      const size = file.size / 1024 / 1024
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    handleRemove(file, fileList) {
    let index = this.getArrayIndex(this.repairBeforFilefileList,file);
    this.form.designImageUrlList.splice(index,1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    msgLimit(file,fileList){
      this.$message.error("最多上传20张图片");
    },
      //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
      }),
       this.searchDatatime=[];
        this.getList(1)
    },
    getList(current) {
      this.search.startTime = ''
        this.search.endTime = ''
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      if(this.activeName=='one'){
          if (current) {
                this.search1.current = 1
            }
          bzWorkOrderCheck_pageDesign(this.search1).then(res => {
                this.tableData1 = res.data.records
                this.total = res.data.total
            })
      }
      if(this.activeName=='two'){
        if (current) {
            this.search.current = 1
        }
        bzWorkOrderCheck_pageDesignRecord(this.search).then(res => {
                this.tableData2 = res.data.records
                this.total = res.data.total
            })
     }
    },
   

    handleSizeChange(val) {
        if(this.activeName=='one'){
            this.search1.size = val
        }else{
            this.search.size = val
        }
      
      this.getList(1)
    },
    handleCurrentChange(val) {
        if(this.activeName=='one'){
            this.search1.current = val
        }else{
            this.search.current = val
        }
      this.getList()
    },
    changStatus(row,type){
        this.form.remark = row.remark;
        this.form.id = row.id;
        this.operatorType = type
        this.dialogVisible = true;
    },
    desc(row,type){
      this.id = row.applyId+"";
      this.type = type+""
      this.$refs.applyDesc.setdialogVisible(true)
    },
    clearnF(){
        this.form={
            id:'',
            remark:'',
            designImageUrlList:[]
        }
        if(this.$refs['addform']){
            this.$refs['addform'].resetFields();
        }
        this.repairBeforFilefileList=[];
    },
    saveadd(){
        if(this.form.designImageUrlList&&this.form.designImageUrlList.length>0){
            bzWorkOrderCheck_uploadDesign(this.form).then(res=>{
                this.$message.success('上传设计成功！')
                this.dialogVisible = false;
                this.getList();
                this.clearnF();
            })
        }else{
            this.$message.error('请上传设计图')
        }
    },
    look(row){
        this.form.designImageUrlList = row.designImageUrlList;
        this.form.remark = row.remark;
        this.dialogVisibleDesc = true;
    }
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.tab{background-color: #fff;border-radius: 6px;margin: 10px;}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin-right: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;
  height: auto;
}
.table-height {
    height: calc(100vh - 410px);
}
.table-height1 {
    height: calc(100vh - 320px);
    overflow: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
   height: calc(100vh - 90px);
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
